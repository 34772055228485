interactiveAreaInterval = null;

$(document).ready(function ($) {
	// screenshot slider
	if ($.fn.sudoSlider) {
		$('#video-container').sudoSlider({
			vertical: false,
			auto: true,
			autowidth: false,
			continuous: true,
			prevNext: false,
			numeric: false,
			slideCount: 1,
			speed: 250,
			responsive: true
		});
	}
});

function initInteractiveAreas() {
	$('.video-player-interactive-area').remove();
	$('.video-player-interactive-area-template').each(function() {
		var areaClone = $(this).clone();
		var rewindTo = parseInt($(this).attr('data-rewind-to'));
		areaClone.on('click', function() {
			$('.video-player-interactive-area').hide();
			jwplayer().seek(rewindTo);
		});
		areaClone.removeClass('video-player-interactive-area-template');
		areaClone.addClass('video-player-interactive-area');
		$('#video-container').append(areaClone);
	});
	if (interactiveAreaInterval) {
		clearInterval(interactiveAreaInterval);
	};
	interactiveAreaInterval = setInterval(function() {
		updateInteractiveAreas();
	}, 1000);
}

function updateInteractiveAreas() {
	$('.video-player-interactive-area').each(function() {
		var showSince = parseInt($(this).attr('data-show-since'));
		var showUntil = parseInt($(this).attr('data-show-until'));
		var position = jwplayer().getPosition();
		if (position) {
			if (showSince < position && showUntil > position) {
				$(this).show();
			} else {
				$(this).hide();
			}
		}
	});
}

function configurePlayer(player) {
	var playerContainer = player.getContainer();
	player.addButton(
		'<svg class="jw-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" focusable="false"><path d="M 25.993176,57.778 v 125.3 a -4.867,4.867 0 0 0 4.8,4.8 h 62.7 v -19.3 h -48.2 v -96.4 H 160.99318 v 19.3 c 0,5.3 3.6,7.2 8,4.3 l 41.8,-27.9 a -6.013,6.013 0 0 0 2.7,-8 -5.887,5.887 0 0 0 -2.7,-2.7 l -41.8,-27.9 c -4.4,-2.9 -8,-1 -8,4.3 v 19.3 H 30.893176 a -4.974,4.974 0 0 0 -4.9,4.9 z M183.2,131.078a21.589,21.589,0,0,0-17.7-10.6,21.589,21.589,0,0,0-17.7,10.6,44.769,44.769,0,0,0,0,46.3,21.589,21.589,0,0,0,17.7,10.6,21.589,21.589,0,0,0,17.7-10.6,44.769,44.769,0,0,0,0-46.3Zm-17.7,47.2c-7.8,0-14.4-11-14.4-24.1s6.6-24.1,14.4-24.1,14.4,11,14.4,24.1S173.4,178.278,165.5,178.278Zm-43.4,9.7v-51l-4.8,4.8-6.8-6.8,13-13a4.8,4.8,0,0,1,8.2,3.4v62.7l-9.6-.1Z"></path></svg>',
		'Přeskočit o 10 sekund vpřed',
		function () { player.seek((player.getPosition() + 10)); },
		'rewind_forward'
	);
	const button = playerContainer.querySelector('.jw-icon[button="rewind_forward"]');
	$(button).insertAfter(playerContainer.querySelector('.jw-button-container .jw-icon-rewind'));

	player.on('firstFrame', function () {
		const captions = player.getCaptionsList();
		if (captions.length > 1) {
			player.setCurrentCaptions(1);
		}
	});
}
