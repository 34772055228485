var customForm_initRadioDepended, initTooltips, initSlots;

(function () {

	var radioDependedData = undefined;
	function initRadioDepeneded(container, data) {
		if (data === undefined) {
			if (radioDependedData === undefined) {
				return;
			}
			data = radioDependedData;
		} else {
			radioDependedData = data;
		}

		var form = container.find(".form-custom");

		function cellByInputName(name) {
			return form.find("[name=" + name + "], #" + name).parents(".cell:first");
		}

		$.each(data, function (name, inputsByValue) {
			form.find("[name=" + name + "]")
				.change(function (e) {
					if (!e.isTrigger) {
						form.find("[name=" + name + "]").not(this).trigger("change");
					}
					var i = Number($(this).val());
					if (inputsByValue[i] !== undefined) {
						var checked = $(this).prop("checked");
						$.each(inputsByValue[i], function (j, dependedName) {
							var cell = cellByInputName(dependedName);
							if (checked) {
								cell.show();
							} else {
								cell.hide();
							}
						});
					}
				})
				.trigger("change");

			$.each(inputsByValue, function (value, inputNames) {
				$.each(inputNames, function (i, name) {
					cellByInputName(name).addClass("radio-depended");
				});
			});
		});
	}

	initTooltips = function (container) {

		function parseLabelText(text) {
			var input = String(text);
			var output = "";
			var label = "";
			var buffer = "";
			var title, end;

			while (input.length > 0) {
				var labelOffset = input.indexOf("[[");
				if (labelOffset < 0) {
					labelOffset = Infinity;
				}
				var titleOffset = input.indexOf("{{");
				if (titleOffset < 0) {
					titleOffset = Infinity;
				}

				var first = Math.min(labelOffset, titleOffset);
				if (first === Infinity) {
					output += label + buffer + input;
					break;
				}

				var type = input.substr(first, 2);

				buffer += input.substr(0, first);
				input = input.substr(first + 2);

				if (type === "[[") {
					end = input.indexOf("]]");
					if (end < 0) {
						buffer += "[[";
						continue;
					}

					output += label + buffer;
					buffer = "";

					label = input.substr(0, end);
					input = input.substr(end + 2);
				} else if (type === "{{") {
					end = input.indexOf("}}");
					if (end < 0) {
						buffer += "{{";
						continue;
					}

					title = input.substr(0, end);
					input = input.substr(end + 2);

					if (label.length === 0) {
						label = buffer;
						buffer = "";
					}

					output += $("<span />").prop("title", title).text(label)[0].outerHTML;
					label = "";
				}
			}

			return output;
		}

		var form = container.find(".form-custom");

		form.find("label, .label").each(function () {
			var elm = $(this);
			elm.html(parseLabelText(elm.html()));
		});

		form.find("label *[title], .contact-form-slots *[title]").each(function () {
			var elm = $(this);

			var title = elm.data("title");
			if (!title) {
				var title = elm.prop("title");
				elm.prop("title", "").attr("data-title", title);
			}

			elm.children('.tooltip-container').remove();
			var tooltip = $("<span class='tooltip-container'><span class='tooltip' /></span>")
				.appendTo(elm);
			tooltip
				.children(".tooltip")
				.html(title);
			tooltip
				.css("left", (elm.width() - tooltip.width()) / 2 + "px");

			var hideAll = function () {
				form.find("label *[title] .tooltip-container").hide();
			};

			var touchstart = false;
			var touchHideTimeout = null;

			elm
				.mouseenter(function () {
					tooltip.show();
				})
				.mouseleave(function () {
					tooltip.hide();
				})
				.bind("touchstart", function () {
					if (tooltip.is(":visible")) {
						return;
					}
					hideAll();
					tooltip.show();
					touchstart = true;
					clearTimeout(touchHideTimeout);
					touchHideTimeout = setTimeout(function () {
						touchstart = false;
					}, 300);
				});

			$(document).bind("touchstart", function () {
				if (!touchstart) {
					tooltip.hide();
				}
			});
		});
	}

	initSlots = function (form) {
		var limits = { w: 2, p: 1 };

		form.find('.contact-form-slots').each(function () {
			var container = $(this);
			var radios = container.find('input[type=radio]').filter(function () {
				var radio = $(this);
				if (radio.prop('disabled')) {
					radio.closest('.toggle').addClass('disabled');
					return false;
				}
				return true;
			});
			var update = function (e) {
				var counts = { w: 0, p: 0 };
				radios.each(function () {
					var radio = $(this);
					if (radio.prop('checked')) {
						counts[radio.prop('value').substr(0, 1)]++;
					}
				});
				$.each(counts, function (type, count) {
					var typeRadios = radios.filter('*[value^="' + type + '"]');
					if (count > limits[type]) {
						typeRadios.prop('checked', false).parent().removeClass('checked');
						$(e.target).prop('checked', true).parent().addClass('checked');
						typeRadios.trigger('change');
						if (limits[type] > 1) {
							typeRadios.closest('.toggle').removeClass('disabled');
							return true;
						}
					} else if (count < limits[type]) {
						typeRadios.closest('.toggle').removeClass('disabled');
						return true;
					}

					typeRadios.each(function () {
						var radio = $(this);
						radio.closest('.toggle').toggleClass(
							'disabled',
							typeRadios.filter('*[name="' + radio.prop('name') + '"]:checked').length === 0
						);
					});
				});
			};
			radios.change(update);
		});
	}

	var contactForm = $("[id=custom-contact-form-wrapper]");
	if (contactForm.length > 0) {
		initTooltips(contactForm);
		initSlots(contactForm);
	}

	var origHtml = $.fn.html;
	$.fn.html = function ()
	{
		var isCustomForm = this.find("form.form-custom").length > 0;
		var result = origHtml.apply(this, arguments);
		if (isCustomForm) {
			initTooltips(this);
			initSlots(this);
			initRadioDepeneded(this);
		}
		return result;
	};

	customForm_initRadioDepended = initRadioDepeneded;

})();
